import { BASE } from "@/api/index";
import axios from "@/api/http";

const BASE_URL = BASE.PRO1;

/**
 * 总控-品类管理-父类列表
 * @param {*} params
 * @returns
 */
export function getCategoriesList(params = {}) {
  return axios.get(`${BASE_URL}/categories/list`, { params });
}

/**
 * 总控-品类管理-子类列表
 * @param {*} params
 * @returns
 */
export function getCategories(params = {}) {
  return axios.get(`${BASE_URL}/get/categories`, { params });
}

/**
 * 总控-品类管理-添加品类
 * @param {*} params
 * @returns
 */
export function postAddCategories(params = {}) {
  return axios.post(`${BASE_URL}/add/categories`, params);
}

/**
 * 总控-品类管理-编辑品类
 * @param {*} params
 * @returns
 */
export function postUpdateCategories(params = {}) {
  return axios.post(`${BASE_URL}/update/categories`, params);
}

/**
 * 总控-品类管理-删除品类
 * @param {*} params
 * @returns
 */
export function postDeleteCategories(params = {}) {
  return axios.post(`${BASE_URL}/delete/categories`, params);
}

/**
 * 总控-全部品类(不需要分页)
 * @param {*} params
 * @returns
 */
export function getAllCategoriesList(params = {}) {
  return axios.get(`${BASE_URL}/categoriesList`, params);
}
